<template>
	<Dialog :dialog="approveDialog" :dialog-width="700">
		<template v-slot:title> Approve {{ type }}</template>
		<template v-slot:body>
			<v-form
				v-if="approveDialog"
				class="quoteApproveForm"
				ref="quoteApproveForm"
				lazy-validation
				v-on:submit.stop.prevent="approveSubmit()"
			>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 my-auto">
						<v-icon color="green" size="40">mdi-checkbox-marked-circle</v-icon>
						<span class="svg-icon svg-icon-lg delete-confirm-icon" v-if="false">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">Are you sure to approve {{ type }}</p>
					</v-col>
					<v-col v-if="approveNote" md="12" class="pb-0 my-auto">
						<em>Note: {{ approveNote }}</em>
					</v-col>
					<template v-if="approveType == 'quotation' || approveType == 'project-transfer'">
						<v-col md="12" class="py-0 my-auto mt-4">
							<v-flex>
								<label class="font-weight-500 font-size-16 required">Remark</label>
								<TextAreaInput
									:disabled="pageLoading"
									:loading="pageLoading"
									id="remark"
									placeholder="Remark"
									v-model="remark"
									:rules="[vrules.required(remark, 'Remark')]"
									:class="{
										required: !remark,
									}"
								>
								</TextAreaInput>
							</v-flex>
						</v-col>

						<v-col md="12" class="py-0 my-auto" v-if="approveType != 'project-transfer'">
							<v-flex md12>
								<label for="remark" class="btx-label mt-2">Attachments</label>
								<FileUpload v-model="files"></FileUpload>
							</v-flex>
						</v-col>
					</template>
				</v-row>
			</v-form>
		</template>

		<template v-slot:action>
			<v-btn depressed tile :disabled="approveButton" v-on:click="$emit('close', true)">
				No, Close
			</v-btn>
			<slot name="extra-btn"></slot>
			<v-btn
				class="white--text"
				:loading="approveButton"
				:disabled="approveButton"
				depressed
				color="green lighten-1"
				tile
				v-on:click="approveSubmit()"
			>
				Yes! Approve
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { GetLineItem } from "@/core/lib/request-order.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
//import Draggable from "vuedraggable";
//import TextInput from "@/view/components/TextInput";
//import InputEdit from "@/view/components/InputEdit";
//import ShowValue from "@/view/components/ShowValue";
import FileUpload from "@/view/components/FileUpload";
import TextAreaInput from "@/view/components/TextAreaInput";
import { map } from "lodash";

export default {
	name: "delete-template",
	title: "Delete Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		status: {
			type: String,
			default: "mark_as_approved",
		},
		uuid: {
			type: String,
			default: null,
		},
		approveType: {
			type: String,
			default: null,
		},
		approveNote: {
			type: String,
			default: null,
		},
		approveDialog: {
			type: Boolean,
			default: false,
		},
		updateStatus: {
			type: Boolean,
			default: true,
		},
		id: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			pageLoading: false,
			approveButton: false,
			remark: null,
			files: [],
		};
	},
	components: {
		Dialog,

		FileUpload,
		TextAreaInput,
	},
	methods: {
		async getPoLineItems(id) {
			const param = {
				purchase_order: id,
				option: "line_items",
			};
			this.contentLoading = true;
			const data = await GetLineItem(param);

			this.contentLoading = false;
			this.line_items_detail = data.detail;
			this.line_items = data.line_items;
		},

		getTitle() {
			return "Line Items";
		},

		approveSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.quoteApproveForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.quoteApproveForm.validate()) {
				return false;
			}

			if (this.updateStatus) {
				_this.approveButton = true;

				const attachmenets = map(this.files, (row) => {
					return { ...row, file: null };
				});

				const payload = {
					action: _this.status,
					selected: [_this.uuid],
					uuid: _this.uuid,
					line_items: _this.line_items,
					detail: _this.detail,
					files: attachmenets,
					admin_remark: _this.admin_remark,
					remark: _this.remark ? _this.remark : null,

					//files: attachmenets,
				};
				ApiService.patch(`${_this.approveType}/bulk-action/status`, payload)
					.then(() => {
						_this.$emit("success");
						_this.$emit("close", true);
						_this.$store.dispatch(SET_LOCAL_DB);
						_this.$store.commit(SET_MESSAGE, [
							{ model: true, message: `Success ! ${_this.type} has been approved.` },
						]);
					})
					.catch((error) => {
						_this.logError(error);
					})
					.finally(() => {
						_this.approveButton = false;
					});
			} else {
				_this.$emit("success");
			}
		},
	},

	mounted() {
		if (this.id) {
			this.getPoLineItems(this.id);
		}
	},
	computed: {
		...mapGetters(["errors"]),
	},
};
</script>
