<template>
	<div>
		<div style="width: 60px" class="mx-auto" v-if="isEdit">
			<TextInput
				v-model="input_val"
				hide-details
				type="number"
				hide-spin-buttons
				autofocus
				class="mt-0 px-1"
				@blur="updateValue()"
			></TextInput>
			<!-- <v-icon color="blue" @clcik="isEdit">mdi-pencil</v-icon> -->
		</div>
		<div
			v-else
			style="width: 60px"
			class="mx-auto d-flex align-items-center justify-content-between px-1"
		>
			<div class="px-1 py-1">{{ value }}</div>
			<v-icon class="cursor-pointer" color="blue darken-4" size="16" @click="isEdit = true"
				>mdi-pencil</v-icon
			>
		</div>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "editInput",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			isEdit: false,
			input_val: null,
		};
	},
	methods: {
		updateValue() {
			(this.isEdit = false), this.$emit("input", Number(this.input_val));
		},
	},
	components: {
		TextInput,
	},
	mounted() {
		this.input_val = this.value;
	},
};
</script>
