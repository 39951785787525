<template>
	<div>
		<div>
			<v-layout class="p-4 border-bottom-light-grey min-height-57px">
				<v-flex md5 class="font-level-3-bold my-auto">
					<span class="detail-svg-icon mr-2">
						<!--begin::Svg Icon-->
						<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
						<!--end::Svg Icon-->
					</span>
					{{ getTitle() }}
				</v-flex>
				<v-flex
					md7
					class="font-level-3-bold my-auto d-flex justify-content-end align-items-center"
					v-if="false"
				>
					<span class="mr-1">AMOUNT </span
					><span style="font-size: 24px" class="blue--text text--darken-4">$200</span>
				</v-flex>
			</v-layout>
			<template v-if="contentLoading">
				<Loading />
			</template>
			<div v-else class="overflow-y" style="max-height: calc(100vh - 306px)">
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th class="p-2 blue lighten-4" width="80"></th>
							<th class="p-2 blue lighten-4">Purchase Order</th>
							<th class="p-2 blue lighten-4">Detail</th>
							<th class="p-2 blue lighten-4">Customer</th>
							<th class="p-2 blue lighten-4">Supplier</th>
							<th class="p-2 blue lighten-4">Project</th>
						</tr>
					</thead>
					<!-- <tbody v-if="po_history.length"> -->
					<template v-if="po_history.length">
						<tr class="cursor-pointer" @click="goToPurchaseOrder(row)" v-for="(row, index) in po_history" :key="index">
							<td class="p-2 border-top-light-grey">
								{{ index + 1 }}
							</td>
							<td class="p-2 border-top-light-grey">
								<div class="mb-2">
									<v-chip label color="cyan" text-color="white" class="r">
										{{ row?.barcode }}
									</v-chip>
									<div class="mt-2">
										<v-chip label :color="getStatusColor(row.status_value)" text-color="white" class="r">
											{{ row?.status_value }}
										</v-chip>
									</div>
								</div>
							</td>
							<td class="p-2 border-top-light-grey">
								<div>
									<span class="fw-500">Ref : </span>
									<ShowValue
										tooltip
										tooltip-text="reference number"
										:object="row"
										object-key="reference_number"
										label="reference number"
									></ShowValue>
								</div>
								<div>
									<span class="fw-500">Amount : </span>
									<span class="fw-600">
										<ShowPrice :object="row" object-key="total" label="amount"> </ShowPrice>
									</span>
									<v-icon v-if="false && row.is_price_changed" color="red" size="12" class="ml-1"
										>mdi-circle</v-icon
									>
								</div>
								<div>
									<span class="fw-500">Delivery Date : </span>
									<template v-if="row.date">
										{{ formatDate(row.date) }}
									</template>
									<template v-else>
										<em class="text-muted">no date</em>
									</template>
								</div>
							</td>
							<td class="p-2 border-top-light-grey">
								<div class="">
									<div>
										<v-icon small left>mdi-barcode</v-icon>
										<v-chip
											v-if="row.customer_barcode"
											outlined
											small
											label
											color="cyan white--text text-uppercase"
											class="px-2"
										>
											{{ row.customer_barcode }}
										</v-chip>
										<em v-else class="text-muted fw-400">no barcode</em>
									</div>
									<div>
										<v-icon small left>mdi-home-city-outline</v-icon>
										<ShowValue
											tooltip
											tooltip-text="company name"
											:object="row"
											object-key="customer_company_name"
											label="company name"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-account</v-icon>
										<ShowValue
											tooltip
											tooltip-text="display name"
											:object="row"
											object-key="customer_name"
											label="display name"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-email</v-icon>
										<ShowValue
											tooltip
											click-to-copy
											tooltip-text="Email"
											:object="row"
											object-key="customer_email"
											label="email"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-phone</v-icon>
										<ShowValue
											tooltip
											click-to-copy
											tooltip-text="phone number"
											:object="row"
											object-key="customer_phone"
											label="phone number"
										>
										</ShowValue>
									</div>
								</div>
							</td>
							<td class="p-2 border-top-light-grey">
								<div class="">
									<div>
										<v-icon small left>mdi-barcode</v-icon>
										<v-chip
											v-if="row.supplier_barcode"
											outlined
											small
											label
											color="cyan white--text text-uppercase"
											class="px-2"
										>
											{{ row.supplier_barcode }}
										</v-chip>
										<em v-else class="text-muted fw-400">no barcode</em>
									</div>
									<div class="d-block">
										<v-icon small left>mdi-home-city-outline</v-icon>
										<ShowValue
											tooltip
											tooltip-text="company name"
											:object="row"
											object-key="supplier_company_name"
											label="company name"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-account</v-icon>
										<ShowValue
											tooltip
											tooltip-text="display name"
											:object="row"
											object-key="supplier_name"
											label="display name"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-email</v-icon>
										<ShowValue
											tooltip
											click-to-copy
											tooltip-text="Email"
											:object="row"
											object-key="supplier_email"
											label="email"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-phone</v-icon>
										<ShowValue
											tooltip
											click-to-copy
											tooltip-text="phone number"
											:object="row"
											object-key="supplier_phone"
											label="phone number"
										>
										</ShowValue>
									</div>
								</div>
							</td>
							<td class="p-2 border-top-light-grey">
								<div class="text-truncate">
									<span class="fw-500">Name : </span>
									<ShowValue
										truncate
										:object="row"
										object-key="project_name"
										label="project name"
									></ShowValue>
								</div>
								<div class="d-flex">
									<span>
										<span class="fw-500">Price : </span>
										<span class="fw-600">
											<ShowPrice :object="row" object-key="project_price" label="project cost"></ShowPrice>
										</span>
									</span>
									<v-spacer></v-spacer>
									<v-tooltip top v-if="false">
										<template v-slot:activator="{ on, attrs }">
											<v-icon
												v-on="on"
												v-bind="attrs"
												class="text-right"
												size="16"
												:color="getStatusColor(row.project_status_value)"
												>mdi-circle</v-icon
											>
										</template>
										<span
											>Project Status -
											<span class="text-uppercase">{{ row.project_status_value }}</span></span
										>
									</v-tooltip>
								</div>
								<div>
									<span class="fw-500">Start : </span>
									<template v-if="row.project_start_date">
										{{ formatDate(row.project_start_date) }}
									</template>
									<template v-else>
										<em class="text-muted">no start date</em>
									</template>
								</div>
								<div>
									<span class="fw-500">End : </span>
									<template v-if="row.project_end_date">
										{{ formatDate(row.project_end_date) }}
									</template>
									<template v-else>
										<em class="text-muted">no end date</em>
									</template>
								</div>
								<div v-if="row.project_status_value">
									<span class="fw-500">Status : </span>
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-chip
												:color="getStatusColor(row.project_status_value)"
												label
												small
												v-on="on"
												v-bind="attrs"
												class="white--text"
											>
												<span class="text-uppercase">
													{{ row.project_status_value }}
												</span>
											</v-chip>
										</template>
										<span>Project Status</span>
									</v-tooltip>
								</div>
							</td>

							<td
								class="p-2 border-top-light-grey d-flex justify-end"
								v-if="requestPoData && requestPoData.id && purcashStaus == 1"
							>
								<v-btn
									depressed
									fab
									x-small
									:color="requestPoData && requestPoData.id && row.is_approve == 1 ? 'blue' : 'green'"
									class="mr-3"
									v-on:click="PendingDialog(row)"
								>
									<v-icon color="white">mdi-pencil</v-icon>
								</v-btn>
								<v-btn depressed fab x-small color="red" v-on:click="PendingProductDelete(row)">
									<v-icon color="white">mdi-delete</v-icon>
								</v-btn>
							</td>
							<td
								class="p-2 border-top-light-grey text-center d-flex justify-end"
								v-if="false && requestPoData && requestPoData.id && row.is_approve == 1"
							>
								<v-btn depressed fab x-small color="green" class="mr-3">
									<v-icon color="white">mdi-check</v-icon>
								</v-btn>
								<!-- <v-btn depressed fab x-small color="red" v-on:click="PendingProductDelete(row)">
										<v-icon color="white">mdi-delete</v-icon>
									</v-btn> -->
							</td>
						</tr>
					</template>
					<!-- </tbody> -->
					<tfoot v-else>
						<tr>
							<td colspan="6">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no {{ getTitle() }} at the moment.</span>
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
// import { DeleteInventoryLineItem } from "@/core/lib/inventory.lib";
import { GetPOHistory } from "@/core/lib/purchase-order.lib";
import Loading from "@/view/components/Loading";
// import { SET_ERROR } from "@/core/services/store/common.module";
export default {
	props: {
		type: {
			type: String,
			default: null,
		},
		requestPoData: {
			type: Object,
			default: null,
		},
		typeText: {
			type: String,
			default: null,
		},
		itemType: {
			type: String,
			default: null,
		},
		uuid: {
			type: String,
			default: null,
		},
		id: {
			type: Number,
			default: 0,
		},
		purcashStaus: {
			type: Number,
			default: 0,
		},
		status: {
			type: String,
			default: "all",
		},
	},
	data() {
		return {
			contentLoading: false,
			pendingProductDialog: false,
			singleproductdata: {},
			po_history: [],
			line_items_detail: {
				total: 1111,
				sub_total: 1111,
				tax_type: 1,
				discount_type: 2,
				discount_value_type: 2,
				total_items: null,
				discount_value: 15,
				discount_amount: 10,
				tax_value: 7,
				taxable_amount: 0,
				tax_amount: 0,
				adjustment: 100,
				tax_applied: 0,
				gst: 0,
			},
			pageLoading: true,
		};
	},
	methods: {
		PendingDialog(params) {
			this.singleproductdata = params;
			this.pendingProductDialog = true;
		},
		goToPurchaseOrder(row) {
			this.$router.push({
				name: "purchase-order-detail",
				params: {
					uuid: row.uuid,
				},
			});
		},
		refreshProducts() {
			this.getPoLineItems(this.id);
		},
		LineItemDetail(uuid) {
			this.$router.push({
				name: "product-detail",
				params: { uuid },
				query: { t: new Date().getTime() },
			});
		},

		getTitle() {
			return "Purchase Order History";
		},
		async getPOHistory() {
			const { po_list } = await GetPOHistory(this.$route?.params?.uuid);
			this.po_history = po_list;
		},
	},
	components: {
		ShowPrice,
		ShowValue,
		Loading,
	},
	mounted() {
		this.getPOHistory();
	},
};
</script>
